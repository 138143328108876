<template>
  <v-container class="pa-0 password-rules fs-12">
    <v-row no-gutters>
      <v-col :cols="colsNum" :class="verify.hasUpper ? 'green' : 'red'" class="d-flex align-center">
        <v-icon :icon="verify.hasUpper ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline'" size="18" class="mr-1"></v-icon>1 uppercase letter
      </v-col>
      <v-col :cols="colsNum" :class="verify.hasLower ? 'green' : 'red'" class="red d-flex align-center">
        <v-icon :icon="verify.hasLower ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline'" size="18" class="mr-1"></v-icon>1 lowercase letter
      </v-col>
      <v-col :cols="colsNum" :class="verify.hasNumber ? 'green' : 'red'" class="red d-flex align-center">
        <v-icon :icon="verify.hasNumber ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline'" size="18" class="mr-1"></v-icon>1 number
      </v-col>
      <v-col :cols="colsNum" :class="verify.hasLength ? 'green' : 'red'" class="red d-flex align-center">
        <v-icon :icon="verify.hasLength ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline'" size="18" class="mr-1"></v-icon>8 characters minimum
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
const props = defineProps({
  colsNum:{
    type:Number,
    default:6
  }
})
const hasVerify = ref(false)
const verify = ref({
  hasUpper: false,
  hasLower: false,
  hasNumber: false,
  hasLength: false
})

/**
 * 密码校验规则
 * @param {str} 密码
 */
const passwordRules = str => {
  // 大写字母
  verify.value.hasUpper = /[A-Z]/.test(str)
  // 小写字母
  verify.value.hasLower = /[a-z]/.test(str)
  // 数字
  verify.value.hasNumber = /\d/.test(str)
  // 长度
  verify.value.hasLength = str?.length > 7 ? true : false

  str ? (hasVerify.value = true) : (hasVerify.value = false)
  if (verify.value.hasUpper && verify.value.hasLower && verify.value.hasNumber && verify.value.hasLength) {
    hasVerify.value = false
  }
}

defineExpose({
  passwordRules
})
</script>

<style lang="scss" scoped>
.password-rules {
  line-height: 24px;
  .red {
    color: #f44336;
  }
  .green {
    color: #4caf50;
  }
}
</style>